import React, { Component } from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import BombReview from "./components/BombReview";
import { db } from "../../firestore/initialization";
import { Link } from "react-router-dom";

class AddScreen extends Component {
  state = {
    kid: "Grant",
    bombText: "",
    bombs: {},
  };

  componentDidMount() {
    db.ref("bombs")
      .once("value")
      .then((snapshot) => {
        const bombs = {};

        snapshot.forEach((childSnap) => {
          bombs[childSnap.key] = {
            ...childSnap.val(),
          };
        });

        this.setState({
          bombs,
        });
      });
  }

  changePerson = (kid) => {
    this.setState({
      kid,
    });
  };

  addBomb = (e) => {
    e.preventDefault();

    if (!this.state.bombText.trim()) {
      return;
    }

    const bombRef = db.ref("bombs").push();

    const newObj = {
      kid: this.state.kid,
      bombText: this.state.bombText,
      id: bombRef.key,
    };

    bombRef.set(newObj);

    this.setState((prevState) => {
      return {
        bombs: {
          ...prevState.bombs,
          [bombRef.key]: newObj,
        },
        bombText: "",
      };
    });
  };

  deleteBomb = (bombId) => {
    db.ref(`bombs/${bombId}`).remove();

    this.setState((prevState) => {
      return {
        bombs: removeProperty(prevState.bombs, bombId),
      };
    });
  };

  render() {
    return (
      <Page>
        <Link to="/" exact={true}>
          <BackButton icon={faChevronLeft} style={{ marginBottom: 20 }} />
        </Link>

        <InnerContainer>
          <div style={{ marginBottom: 30 }}>
            <Label>Kid:</Label>
            <PersonSelect
              onChange={(e) => this.changePerson(e.target.value)}
              value={this.state.kid}
            >
              <PersonOption value="Grant">Grant</PersonOption>
              <PersonOption value="Faith">Faith</PersonOption>
              <PersonOption value="Amanda">Amanda</PersonOption>
            </PersonSelect>
          </div>

          <form>
            <Label>Note:</Label>
            <p style={{ marginTop: 15 }}>My mom is bomb because...</p>
            <NoteInput
              value={this.state.bombText}
              onChange={(e) =>
                this.setState({
                  bombText: e.target.value,
                })
              }
              rows="4"
              placeholder="keep it kinda short"
            />

            <AddButton onClick={this.addBomb}>add new</AddButton>
          </form>

          <BombReview bombs={this.state.bombs} deleteBomb={this.deleteBomb} />
        </InnerContainer>
      </Page>
    );
  }
}

export default AddScreen;

const removeProperty = (obj, property) => {
  let objCopy = Object.assign({}, obj);
  delete objCopy[property];
  return objCopy;
};

const Page = styled.div`
  padding: 20px;
`;

const InnerContainer = styled.div`
  padding: 30px;

  @media (max-width: 700px) {
    padding: 10px;
  }
`;

const BackButton = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const PersonSelect = styled.select`
  outline: none;
  font-size: 17.5px;
  padding: 20px;
  background-color: white;
  border: 1px solid gray;
`;

const PersonOption = styled.option``;

const Label = styled.label`
  font-size: 17.5px;
  margin-right: 10px;
`;

const NoteInput = styled.input`
  outline: none;
  padding: 20px;
  display: block;
  margin-top: 10px;
  font-size: 17.5px;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  border: 1px solid black;
`;

const AddButton = styled.button`
  outline: none;
  cursor: pointer;
  padding: 10px 30px;
  color: black;
  border-radius: 8px;
  display: inline-block;
  margin-top: 20px;
  transition: 0.3s ease;
  border: 1px solid black;

  :hover {
    transform: translateY(2px);
  }

  :active {
    transform: translateY(4px);
  }
`;
