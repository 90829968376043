import * as firebase from "firebase";

const config = {
  apiKey: "AIzaSyAjzPo1LVQTDYuKQJ4WmnLNwzRpVlAJRT0",
  authDomain: "bombmom-25f67.firebaseapp.com",
  databaseURL: "https://bombmom-25f67.firebaseio.com",
  projectId: "bombmom-25f67",
  storageBucket: "bombmom-25f67.appspot.com",
  messagingSenderId: "355823803685",
  appId: "1:355823803685:web:e7f6db0737d697677c4ecc",
  measurementId: "G-GK8R365V0K",
};

export const app = firebase.initializeApp(config);
export const auth = firebase.auth();
export const db = firebase.database();

export default db;
