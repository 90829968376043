import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { db } from "../../firestore/initialization";

const PointsScreen = () => {
  const [points, setPoints] = useState(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    db.ref("points")
      .once("value")
      .then((snapshot) => {
        if (snapshot.val()) {
          setPoints(snapshot.val());
        }
        setLoaded(true);
      });
  }, []);

  return (
    <Container>
      <CenterContainer>
        <Title>엄마's Massage Points</Title>
        <MassagePoints>{loaded ? points : "\u00A0"}</MassagePoints>
        <HoursLeft>minutes left</HoursLeft>
      </CenterContainer>
    </Container>
  );
};

export default PointsScreen;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
`;

const CenterContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Title = styled.p`
  font-size: 30px;
  text-align: center;

  @media (max-width: 700px) {
    font-size: 25px;
  }
`;

const MassagePoints = styled.p`
  font-size: 200px;
  text-align: center;
  color: #a20a0a;

  @media (max-width: 700px) {
    font-size: 130px;
  }
`;

const HoursLeft = styled.p`
  font-size: 30px;
  text-align: center;

  @media (max-width: 700px) {
    font-size: 25px;
  }
`;
