import React, { Component } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

class BombReview extends Component {
  state = {
    viewKid: "Grant",
  };

  changeChosen = (kid) => {
    this.setState({
      viewKid: kid,
    });
  };

  render() {
    const { bombs } = this.props;

    const bombArr = Object.keys(bombs)
      .map((bombId) => {
        return bombs[bombId];
      })
      .filter((bomb) => {
        return bomb.kid === this.state.viewKid;
      })
      .map((bomb) => {
        return (
          <BombContainer>
            {bomb.bombText}{" "}
            <DeleteButton
              icon={faTimes}
              onClick={() => this.props.deleteBomb(bomb.id)}
            />
          </BombContainer>
        );
      })
      .reverse();

    return (
      <Container>
        <OptionBar style={{ display: "flex", flexDirection: "row" }}>
          <KidOption
            chosen={this.state.viewKid === "Grant"}
            onClick={() => this.changeChosen("Grant")}
          >
            Grant
          </KidOption>
          <KidOption
            chosen={this.state.viewKid === "Faith"}
            onClick={() => this.changeChosen("Faith")}
          >
            Faith
          </KidOption>
          <KidOption
            chosen={this.state.viewKid === "Amanda"}
            onClick={() => this.changeChosen("Amanda")}
          >
            Amanda
          </KidOption>
        </OptionBar>

        <p style={{ marginTop: 20 }}>{`number of notes: ${bombArr.length}`}</p>

        {/* compliment list */}
        <BombListContainer>{bombArr}</BombListContainer>
      </Container>
    );
  }
}

export default BombReview;

const Container = styled.div`
  margin-top: 100px;
  width: 100%;
  max-width: 600px;
`;

const OptionBar = styled.div`
  width: 100%;
  justify-content: space-around;
`;

const KidOption = styled.p`
  cursor: pointer;
  border-bottom: ${(props) => (props.chosen ? "2px solid black" : "none")};
  padding-bottom: 5px;
`;

const BombListContainer = styled.div`
  height: 300px;
  overflow: auto;
  margin-top: 40px;
`;

const BombContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const DeleteButton = styled(FontAwesomeIcon)`
  margin-left: 10px;
  color: red;
  cursor: pointer;
`;
