import React, { Component } from "react";
import "./App.css";

// import { auth } from "./firestore/initialization";
// import { history } from "./router/AppRouter";
import AppRouter from "./router/AppRouter";

class App extends Component {
  componentDidMount() {
    // auth.onAuthStateChanged((user) => {
    //   if (user) {
    //     history.push("/");
    //   } else {
    //     history.push("/login");
    //   }
    // });
  }

  render() {
    return (
      <div className="App">
        <AppRouter />
      </div>
    );
  }
}

export default App;
