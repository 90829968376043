import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import MainScreen from "../screens/MainScreen/MainScreen";
// import LoginScreen from "../screens/LoginScreen/LoginScreen";
import AddScreen from "../screens/AddScreen/AddScreen";
import PointsScreen from "../screens/PointsScreen/PointsScreen";
import UsePointsScreen from "../screens/UsePointsScreen/UsePointsScreen";
import AddPointsScreen from "../screens/AddPointsScreen/AddPointsScreen";

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact={true} component={MainScreen} />
        <Route path="/add-bombs" component={AddScreen} />
        <Route path="/points" component={PointsScreen} />
        <Route path="/use-points" component={UsePointsScreen} />
        <Route path="/add-points" component={AddPointsScreen} />
        {/* <Route path="/login" component={LoginScreen} /> */}
      </Switch>
    </Router>
  );
};

export default AppRouter;
