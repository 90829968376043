import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { db } from "../../firestore/initialization";
import { useHistory } from "react-router-dom";

const UsePointsScreen = () => {
  const [pressable, setPressable] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [currPoints, setCurrPoints] = useState(0);
  const [points, setPoints] = useState(0);
  const history = useHistory();

  useEffect(() => {
    db.ref("points")
      .once("value")
      .then((snapshot) => {
        if (snapshot.val()) {
          setCurrPoints(snapshot.val());
        }
        setLoaded(true);
      });
  }, []);

  const leftButtonClicked = () => {
    if (points <= 0) {
      return;
    }

    setPoints(points - 10);
  };

  const usePressed = () => {
    if (loaded && pressable) {
      setPressable(false);
      const newPoints = currPoints - points;

      db.ref("points")
        .set(newPoints)
        .then(() => {
          history.push("/points");
        })
        .catch((err) => {
          setPressable(true);
        });
    }
  };

  return (
    <Container>
      <CenterContainer>
        <CurrPoints>current points: {loaded ? currPoints : ""}</CurrPoints>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Arrow icon={faChevronLeft} onClick={leftButtonClicked} />
          <PointsContainer>
            <Points>{points}</Points>
          </PointsContainer>
          <Arrow icon={faChevronRight} onClick={() => setPoints(points + 10)} />
        </div>
        <MinutesText>minutes</MinutesText>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <UseButton onClick={usePressed}>use points</UseButton>
        </div>
      </CenterContainer>
    </Container>
  );
};

export default UsePointsScreen;

const CurrPoints = styled.p`
  font-size: 30px;
  margin-bottom: -10px;
  user-select: none;

  @media (max-width: 700px) {
    font-size: 20px;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding: 50px;
`;

const CenterContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const PointsContainer = styled.div`
  width: 400px;

  @media (max-width: 700px) {
    width: 250px;
  }
`;

const Points = styled.p`
  font-size: 200px;
  color: #a20a0a;
  margin: 0px 10px;
  text-align: center;
  user-select: none;

  @media (max-width: 700px) {
    font-size: 115px;
  }
`;

const Arrow = styled(FontAwesomeIcon)`
  font-size: 75px;
  cursor: pointer;

  @media (max-width: 700px) {
    font-size: 30px;
  }
`;

const MinutesText = styled.p`
  font-size: 30px;
  text-align: center;
  user-select: none;
  margin-top: -30px;

  @media (max-width: 700px) {
    font-size: 20px;
    margin-top: -10px;
  }
`;

const UseButton = styled.div`
  display: inline-block;
  text-align: center;
  padding: 10px 40px;
  user-select: none;
  font-size: 30px;
  background-color: #a20a0a;
  margin: 0 auto;
  color: white;
  border-radius: 10px;
  margin-top: 30px;
  cursor: pointer;
  letter-spacing: 1px;

  @media (max-width: 700px) {
    font-size: 20px;
    letter-spacing: 0.5px;
    padding: 10px 30px;
  }
`;
