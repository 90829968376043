import React, { Component } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBomb, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { db } from "../../firestore/initialization";
import "./main.css";
import { Link } from "react-router-dom";

class MainScreen extends Component {
  state = {
    bombs: {},
    currBomb: { bombText: "", kid: "" },
    seen: {},
  };

  componentDidMount() {
    db.ref("bombs")
      .once("value")
      .then((snapshot) => {
        const bombs = {};

        snapshot.forEach((childSnap) => {
          bombs[childSnap.key] = {
            ...childSnap.val(),
          };
        });

        this.setState(
          {
            bombs,
          },
          () => this.getNextBomb()
        );
      });
  }

  getNextBomb = () => {
    const numBombs = Object.keys(this.state.bombs).length;

    if (!numBombs) {
      return;
    }

    if (Object.keys(this.state.seen).length >= numBombs) {
      this.setState(
        {
          seen: {},
        },
        this.getNextBomb
      );
      return;
    }

    const bombs = Object.keys(this.state.bombs);
    let nextIdx = Math.floor(Math.random() * numBombs);
    while (this.state.seen[bombs[nextIdx]]) {
      nextIdx = Math.floor(Math.random() * numBombs);
    }

    this.setState((prevState) => ({
      currBomb: this.state.bombs[bombs[nextIdx]],
      seen: {
        ...prevState.seen,
        [bombs[nextIdx]]: true,
      },
    }));
  };

  render() {
    return (
      <Container>
        <CenterContainer>
          <PromptText>My mom is bomb because</PromptText>
          <BombText className="fadeText" kid={this.state.currBomb.kid}>
            {`${this.state.currBomb.bombText}.`}
          </BombText>
          <NameText>{`- ${this.state.currBomb.kid}`}</NameText>
          {/* <div style={{ textAlign: "center" }}>
            <NextButton onClick={() => this.getNextBomb()}>next</NextButton>
          </div> */}
        </CenterContainer>
        <Link to="/add-bombs">
          <BombIcon icon={faBomb} />
        </Link>
        <NextIcon icon={faChevronRight} onClick={() => this.getNextBomb()} />
      </Container>
    );
  }
}

export default MainScreen;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  /* background-color: #fbf5f3; */
  /* background-color: #fbfef9; */
  background-color: #ffffff;
`;

const CenterContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  font-family: "Montserrat", sans-serif;
  /* border: 1px solid black; */

  @media (max-width: 700px) {
    width: 100%;
    padding: 20px;
    padding-right: 40px;
  }
`;

const Text = styled.h1`
  font-size: 40px;
  letter-spacing: 1px;

  @media (max-width: 700px) {
    font-size: 30px;
  }
`;

const PromptText = styled(Text)`
  user-select: none;
  /* color: #e19999; */
  color: #393d3f;
`;

const BombText = styled(Text)`
  user-select: none;

  color: ${(props) => {
    if (props.kid === "Grant") {
      return "#008dd5";
    } else if (props.kid === "Faith") {
      return "#D81E5B";
    } else {
      return "#9D44B5";
    }
  }};
`;

const NameText = styled(Text)`
  user-select: none;
  color: #d8dbe2;
  margin-top: 10px;
  margin-left: 10px;
  letter-spacing: 0px;

  @media (max-width: 700px) {
    margin-left: 5px;
  }
`;

const BombIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  position: absolute;
  bottom: 30px;
  right: 30px;
  color: lightgray;
  font-size: 40px;
`;

const NextIcon = styled(FontAwesomeIcon)`
  font-size: 40px;
  color: #393d3f;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  cursor: pointer;

  @media (max-width: 700px) {
    right: 20px;
    font-size: 30px;
  }
`;
